import * as types from "./customerStatus.types";

export const contractCustomerStatusListRequestAC: types.CustomerStatusListRequestAC = () => ({
  type: types.CUSTOMER_STATUS_LIST_REQUEST,
});

export const contractCustomerStatusListRequestSuccessAC: types.CustomerStatusListRequestSuccessAC = (payload) => ({
  type: types.CUSTOMER_STATUS_LIST_REQUEST_SUCCESS,
  payload,
});

export const contractCustomerStatusListRequestFailureAC: types.CustomerStatusListRequestFailureAC = () => ({
  type: types.CUSTOMER_STATUS_LIST_REQUEST_FAILURE,
});

export const setCustomerStatusFiltersAC: types.SetCustomerStatusFiltersAC = (payload) => ({
  type: types.SET_CUSTOMER_STATUS_LIST_FILTERS,
  payload,
});

export const setCustomerStatusListSortFieldAC: types.SetCustomerStatusListSortFieldAC = (payload) => ({
  type: types.SET_CUSTOMER_STATUS_LIST_SORT_FIELD,
  payload,
});

export const setCustomerStatusListPerPageAC: types.SetCustomerStatusListPerPageAC = (payload) => ({
  type: types.SET_CUSTOMER_STATUS_LIST_PER_PAGE,
  payload,
});

export const setCustomerStatusListCurrentPageAC: types.SetCustomerStatusListCurrentPageAC = (payload) => ({
  type: types.SET_CUSTOMER_STATUS_LIST_CURRENT_PAGE,
  payload,
});
