import { AppThunk } from "store";
import { authClient } from "api/clients";
import * as appActions from "./app.actions";

export const getUserInfo = (): AppThunk => async (dispatch) => {
  try {
    const userProfile = await authClient.getUserProfile();
    dispatch(appActions.setUserProfile(userProfile));
  } catch (error) {
    console.error(error, "USER INFO REQUEST");
  }
};
