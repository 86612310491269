export const parseApiErrorMessage = (error: any): string | null => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response?.data;
    return message || null;
  } catch (e) {
    return null;
  }
};

export const areFiltersEmpty = (filters: Object) => Object.values(filters).every((value) => value === "");

export const isNumber = (value: any) => typeof value === "number";

export const generateCsvExportUrl = (path: string) => {
  return [(window as any).CUSTOMER_STATUS_API_URL, `/v1/${path}?accept=csv`].join("");
};
