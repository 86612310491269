import { RootState } from "../index";
import { getPaginationSlice, makeUniversalSort } from "utils/table";
import { CustomerStatusFiltersType, CustomerStatusStateSlice } from "./customerStatus.types";
import { CustomerStatusModel } from "types/customerStatusApi.types";

export const getSlice = (state: RootState) => state.customerStatus;
export const getCustomerStatusList = (state: RootState) => state.customerStatus.list;

const makeCustomerNameSortNormalizer = (item: CustomerStatusModel) => item.customerName.toLowerCase();
const makeCustomerIdSortNormalizer = (item: CustomerStatusModel) => Number(item.customerId);
const makeAgreementIdsSortNormalizer = (item: CustomerStatusModel) =>
  Array.isArray(item.agreementIds) ? item.agreementIds.join(", ") : "";
const makeAgreementsSortNormalizer = (item: CustomerStatusModel) =>
  Array.isArray(item.agreements) ? item.agreements.join(", ") : "";
const makeTenantIdsSortNormalizer = (item: CustomerStatusModel) => Object.values(item.tenantIds).join(", ");
const makeSecureScoreSortNormalizer = (item: CustomerStatusModel) => Number(item.secureScore);
const makeNotSignedInFor6MonthsSortNormalizer = (item: CustomerStatusModel) => Number(item.notSignedInFor6Months);
const makeUsersWithMFASortNormalizer = (item: CustomerStatusModel) => Number(item.usersWithMFA);
const makeAdminsWithMFASortNormalizer = (item: CustomerStatusModel) => Number(item.adminsWithMFA);
const makeGlobalAdminsSortNormalizer = (item: CustomerStatusModel) => Number(item.globalAdmins);
const makeBitlockerActivatedSortNormalizer = (item: CustomerStatusModel) => Number(item.bitlockerActivated);
const makeWindows11SortNormalizer = (item: CustomerStatusModel) => Number(item.windows11);
const makeTPMOlderThan20SortNormalizer = (item: CustomerStatusModel) => Number(item["tPMOlderThan2.0"]);
const makeSignedInRiskUsersSortNormalizer = (item: CustomerStatusModel) => Number(item.signedInRiskUsers);
const makeManagedUsersSortNormalizer = (item: CustomerStatusModel) => Number(item.managedUsers);

const customerStatusComposer = ({
  list,
  filters,
  sortField,
  sortOrder,
  currentPage,
  perPage,
}: CustomerStatusStateSlice) => {
  let _list = [...list];

  const sortHandler: any = {
    customerName: makeUniversalSort(makeCustomerNameSortNormalizer),
    customerId: makeUniversalSort(makeCustomerIdSortNormalizer),
    agreementIds: makeUniversalSort(makeAgreementIdsSortNormalizer),
    agreements: makeUniversalSort(makeAgreementsSortNormalizer),
    tenantIds: makeUniversalSort(makeTenantIdsSortNormalizer),
    secureScore: makeUniversalSort(makeSecureScoreSortNormalizer),
    notSignedInFor6Months: makeUniversalSort(makeNotSignedInFor6MonthsSortNormalizer),
    usersWithMFA: makeUniversalSort(makeUsersWithMFASortNormalizer),
    adminsWithMFA: makeUniversalSort(makeAdminsWithMFASortNormalizer),
    globalAdmins: makeUniversalSort(makeGlobalAdminsSortNormalizer),
    bitlockerActivated: makeUniversalSort(makeBitlockerActivatedSortNormalizer),
    windows11: makeUniversalSort(makeWindows11SortNormalizer),
    "tPMOlderThan2.0": makeUniversalSort(makeTPMOlderThan20SortNormalizer),
    signedInRiskUsers: makeUniversalSort(makeSignedInRiskUsersSortNormalizer),
    managedUsers: makeUniversalSort(makeManagedUsersSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  Object.keys(filters).forEach((filterName) => {
    if (filters[filterName as keyof CustomerStatusFiltersType]) {
      _list = _list.filter((item) =>
        item[filterName as keyof CustomerStatusFiltersType]
          ? item[filterName as keyof CustomerStatusFiltersType]
              .toString()
              .toLowerCase()
              .includes(filters[filterName as keyof CustomerStatusFiltersType].toLowerCase())
          : null
      );
    }
  });

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getCustomerStatusTable = (state: RootState) => {
  const customerStatus = getSlice(state);
  return customerStatusComposer(customerStatus);
};
