import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { customerStatusSelectors, customerStatusThunks } from "store/customerStatus";

const useFetchCustomerStatusList = () => {
  const thunkDispatch = useThunkDispatch();

  useEffect(() => {
    thunkDispatch(customerStatusThunks.getCustomerStatusList());
  }, [thunkDispatch]);

  return useSelector(customerStatusSelectors.getSlice);
};

export default useFetchCustomerStatusList;
