import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as customerStatus } from "./customerStatus";
import { reducer as notification } from "./notification";

const rootReducer = combineReducers({
  app,
  customerStatus,
  notification,
});

export default rootReducer;
