import React from "react";
import { Button, Modal } from "@skyportal/ui-kit";
import cn from "classnames";
import { useTranslation } from "react-i18next";
import { CustomerStatusModel } from "types/customerStatusApi.types";
import { customerStatusStyles } from "hooks/useCustomerStatusTableConfig";
import {
  getAdminsWithMFAStatus,
  getBitlockerActivatedStatus,
  getGlobalAdminsStatus,
  getNotSignedInFor6MonthsStatus,
  getSecureScoreStatus,
  getSignedInRiskUsersStatus,
  getTPMOlderThan20Status,
  getUsersWithMFAStatus,
  getWindows11Status,
} from "./helpers";
import { isNumber } from "utils/helpers";

import styles from "./styles.module.css";

interface SelectedCustomerStatusModalProps {
  isOpen: boolean;
  selectedCustomer: CustomerStatusModel;
  handleCloseModalBtn: () => void;
}

const SelectedCustomerStatusModal = ({
  isOpen,
  selectedCustomer,
  handleCloseModalBtn,
}: SelectedCustomerStatusModalProps) => {
  const { t } = useTranslation("customerStatusTable");

  return (
    <>
      <Modal
        open={isOpen}
        onCloseBtnClick={handleCloseModalBtn}
        controls={
          <Button onClick={handleCloseModalBtn} data-testid="customerStatusModalCloseBtn">
            {t("common:Close")}
          </Button>
        }
        title={selectedCustomer.customerName}
        data-testid="customerStatusModal"
      >
        <div className={styles.customerStatusModalBody}>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("customerId")}: </span>
            {selectedCustomer.customerId || "-"}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("agreementIds")}: </span>
            {Array.isArray(selectedCustomer.agreementIds) && selectedCustomer.agreementIds.length
              ? selectedCustomer.agreementIds.join(", ")
              : "-"}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("agreements")}: </span>
            {Array.isArray(selectedCustomer.agreements) && selectedCustomer.agreements.length
              ? selectedCustomer.agreements.join(", ")
              : "-"}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("tenantIds")}: </span>
            {typeof selectedCustomer.tenantIds === "object" && Object.values(selectedCustomer.tenantIds).length
              ? Object.values(selectedCustomer.tenantIds).join(", ")
              : "-"}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("secureScore")}: </span>
            {isNumber(selectedCustomer.secureScore) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getSecureScoreStatus(selectedCustomer.secureScore as number)]
                )}
              >
                {selectedCustomer.secureScore}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("notSignedInFor6Months")}: </span>
            {isNumber(selectedCustomer.notSignedInFor6Months) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getNotSignedInFor6MonthsStatus(selectedCustomer.notSignedInFor6Months as number)]
                )}
              >
                {selectedCustomer.notSignedInFor6Months}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("usersWithMFA")}: </span>
            {isNumber(selectedCustomer.usersWithMFA) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getUsersWithMFAStatus(selectedCustomer.usersWithMFA as number)]
                )}
              >
                {selectedCustomer.usersWithMFA}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("adminsWithMFA")}: </span>
            {isNumber(selectedCustomer.adminsWithMFA) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getAdminsWithMFAStatus(selectedCustomer.adminsWithMFA as number)]
                )}
              >
                {selectedCustomer.adminsWithMFA}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("globalAdmins")}: </span>
            {isNumber(selectedCustomer.globalAdmins) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getGlobalAdminsStatus(selectedCustomer.globalAdmins as number)]
                )}
              >
                {selectedCustomer.globalAdmins}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("bitlockerActivated")}: </span>
            {isNumber(selectedCustomer.bitlockerActivated) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getBitlockerActivatedStatus(selectedCustomer.bitlockerActivated as number)]
                )}
              >
                {selectedCustomer.bitlockerActivated}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("windows11")}: </span>
            {isNumber(selectedCustomer.windows11) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getWindows11Status(selectedCustomer.windows11 as number)]
                )}
              >
                {selectedCustomer.windows11}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("tPMOlderThan2.0")}: </span>
            {isNumber(selectedCustomer["tPMOlderThan2.0"]) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getTPMOlderThan20Status(selectedCustomer["tPMOlderThan2.0"] as number)]
                )}
              >
                {selectedCustomer["tPMOlderThan2.0"]}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("signedInRiskUsers")}: </span>
            {isNumber(selectedCustomer.signedInRiskUsers) ? (
              <div
                className={cn(
                  styles.customerStatusBadge,
                  styles.modalCustomerStatusBadge,
                  customerStatusStyles[getSignedInRiskUsersStatus(selectedCustomer.signedInRiskUsers as number)]
                )}
              >
                {selectedCustomer.signedInRiskUsers}%
              </div>
            ) : (
              "-"
            )}
          </div>
          <div className={styles.customerStatusModalInfo}>
            <span className={styles.customerStatusModalSubtitle}>{t("managedUsers")}: </span>
            {isNumber(selectedCustomer.managedUsers) ? selectedCustomer.managedUsers : "-"}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectedCustomerStatusModal;
