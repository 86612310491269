import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CustomerStatusPage from "pages/CustomerStatusPage";

export const AppRouter: React.FC = () => (
  <Switch>
    <Route path="/customer-status" exact component={CustomerStatusPage} />
    <Route path="*">
      <Redirect to="/customer-status" />
    </Route>
  </Switch>
);

export default AppRouter;
