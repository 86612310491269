import React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "containers/PageHeader/PageHeader";
import CustomerStatusTable from "components/CustomerStatusTable/CustomerStatusTable";
import useFetchCustomerStatusList from "hooks/useFetchCustomerStatusList";

const CustomerStatusPage: React.FC = () => {
  const { t } = useTranslation("customerStatusPage");
  useFetchCustomerStatusList();

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <CustomerStatusTable />
    </PageLayout>
  );
};

export default CustomerStatusPage;
