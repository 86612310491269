export const thresholdsConfig = {
  secureScoreSuccess: 75,
  secureScoreFailure: 65,
  notSignedInFor6MonthsSuccess: 30,
  notSignedInFor6MonthsFailure: 10,
  usersWithMFAStatusSuccess: 100,
  usersWithMFAStatusFailure: 80,
  adminsWithMFAStatusSuccess: 100,
  globalAdminsSuccess: 6,
  globalAdminsFailure: 7,
  bitlockerActivatedSuccess: 95,
  bitlockerActivatedFailure: 80,
  windows11Success: 90,
  windows11Failure: 70,
  "TPMOlderThan2.0Success": 95,
  "TPMOlderThan2.0Failure": 90,
  signedInRiskUsersSuccess: 10,
  signedInRiskUsersFailure: 30,
};
