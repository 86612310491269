import { AppThunk } from "store";
import * as customerStatusApi from "api/customerStatus.api";
import * as customerStatusActions from "./customerStatus.actions";
import i18n from "../../locales";
import { notificationErrorAC } from "store/notification/notification.actions";
import { parseApiErrorMessage } from "utils/helpers";

export const getCustomerStatusList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(customerStatusActions.contractCustomerStatusListRequestAC());
    const { data } = await customerStatusApi.getCustomerStatusList();
    dispatch(customerStatusActions.contractCustomerStatusListRequestSuccessAC(data["customer-status"]));
  } catch (apiErrorMessage) {
    console.error(apiErrorMessage, "CUSTOMER STATUS LIST REQUEST FAILURE");
    dispatch(
      notificationErrorAC({
        message: i18n.t("notification:customerStatusListRequestFailure"),
        description: parseApiErrorMessage(apiErrorMessage),
      })
    );
    dispatch(customerStatusActions.contractCustomerStatusListRequestFailureAC());
  }
};
