import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@skyportal/ui-kit";
import { generateCsvExportUrl } from "utils/helpers";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  title: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  const { t } = useTranslation("customerStatusTable");

  return (
    <header className={styles.customerStatusHeader}>
      <h1 className={styles.customerStatusTitle}>{title}</h1>
      <a href={generateCsvExportUrl("customer-status")} download>
        <Button data-testId="exportAsCsvBtn">{t("customerStatusPage:exportAsCsvBtn")}</Button>
      </a>
    </header>
  );
};

export default PageHeader;
