import { RequestStatus } from "types/common.types";
import {
  CUSTOMER_STATUS_LIST_REQUEST,
  CUSTOMER_STATUS_LIST_REQUEST_FAILURE,
  CUSTOMER_STATUS_LIST_REQUEST_SUCCESS,
  SET_CUSTOMER_STATUS_LIST_FILTERS,
  CustomerStatusListActions,
  CustomerStatusStateSlice,
  SET_CUSTOMER_STATUS_LIST_CURRENT_PAGE,
  SET_CUSTOMER_STATUS_LIST_PER_PAGE,
  SET_CUSTOMER_STATUS_LIST_SORT_FIELD,
} from "./customerStatus.types";

export const emptyCustomerStatusFilters = { customerName: "", customerId: "", agreementIds: "", agreements: "" };

export const initState: CustomerStatusStateSlice = {
  list: [],
  filters: emptyCustomerStatusFilters,
  requestStatus: RequestStatus.UNCALLED,
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
  perPage: 10,
};

export default function customerStatusReducer(
  state: CustomerStatusStateSlice = initState,
  action: CustomerStatusListActions
): CustomerStatusStateSlice {
  switch (action.type) {
    case CUSTOMER_STATUS_LIST_REQUEST: {
      return {
        ...state,
        requestStatus: RequestStatus.PENDING,
      };
    }
    case CUSTOMER_STATUS_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        requestStatus: RequestStatus.SUCCESS,
      };
    }
    case CUSTOMER_STATUS_LIST_REQUEST_FAILURE: {
      return {
        ...initState,
        requestStatus: RequestStatus.FAILURE,
      };
    }
    case SET_CUSTOMER_STATUS_LIST_FILTERS: {
      return {
        ...state,
        filters: action.payload,
      };
    }
    case SET_CUSTOMER_STATUS_LIST_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
        currentPage: 1,
      };
    }
    case SET_CUSTOMER_STATUS_LIST_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case SET_CUSTOMER_STATUS_LIST_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
}
