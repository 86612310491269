import { RequestStatus } from "types/common.types";
import { CustomerStatusModel } from "types/customerStatusApi.types";

export type CustomerStatusFiltersType = Pick<
  Record<keyof CustomerStatusModel, string>,
  "customerName" | "customerId" | "agreementIds" | "agreements"
>;

export interface CustomerStatusStateSlice {
  readonly list: CustomerStatusModel[];
  readonly filters: CustomerStatusFiltersType;
  readonly requestStatus: RequestStatus;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly currentPage: number;
  readonly perPage: number;
}

export type SortFieldType = keyof CustomerStatusModel | "";
export type SortOrderType = "asc" | "desc";

export const CUSTOMER_STATUS_LIST_REQUEST = "CUSTOMER_STATUS_LIST_REQUEST";
export type CustomerStatusListRequestAction = { type: typeof CUSTOMER_STATUS_LIST_REQUEST };
export type CustomerStatusListRequestAC = () => CustomerStatusListRequestAction;

export const CUSTOMER_STATUS_LIST_REQUEST_SUCCESS = "CUSTOMER_STATUS_LIST_REQUEST_SUCCESS";
export type CustomerStatusListRequestSuccessAction = {
  type: typeof CUSTOMER_STATUS_LIST_REQUEST_SUCCESS;
  payload: CustomerStatusModel[];
};
export type CustomerStatusListRequestSuccessAC = (
  payload: CustomerStatusModel[]
) => CustomerStatusListRequestSuccessAction;

export const CUSTOMER_STATUS_LIST_REQUEST_FAILURE = "CUSTOMER_STATUS_LIST_REQUEST_FAILURE";
export type CustomerStatusListRequestFailureAction = { type: typeof CUSTOMER_STATUS_LIST_REQUEST_FAILURE };
export type CustomerStatusListRequestFailureAC = () => CustomerStatusListRequestFailureAction;

export const SET_CUSTOMER_STATUS_LIST_FILTERS = "SET_CUSTOMER_STATUS_LIST_FILTERS";
export type SetCustomerStatusFiltersAction = {
  type: typeof SET_CUSTOMER_STATUS_LIST_FILTERS;
  payload: CustomerStatusStateSlice["filters"];
};
export type SetCustomerStatusFiltersAC = (
  payload: CustomerStatusStateSlice["filters"]
) => SetCustomerStatusFiltersAction;

export const SET_CUSTOMER_STATUS_LIST_SORT_FIELD = "SET_CUSTOMER_STATUS_LIST_SORT_FIELD";
export type SetCustomerStatusListSortFieldAction = {
  type: typeof SET_CUSTOMER_STATUS_LIST_SORT_FIELD;
  payload: SortFieldType;
};
export type SetCustomerStatusListSortFieldAC = (payload: SortFieldType) => SetCustomerStatusListSortFieldAction;

export const SET_CUSTOMER_STATUS_LIST_PER_PAGE = "SET_CUSTOMER_STATUS_LIST_PER_PAGE";
export type SetCustomerStatusListPerPageAction = {
  type: typeof SET_CUSTOMER_STATUS_LIST_PER_PAGE;
  payload: number;
};
export type SetCustomerStatusListPerPageAC = (payload: number) => SetCustomerStatusListPerPageAction;

export const SET_CUSTOMER_STATUS_LIST_CURRENT_PAGE = "SET_CUSTOMER_STATUS_LIST_CURRENT_PAGE";
export type SetCustomerStatusListCurrentPageAction = {
  type: typeof SET_CUSTOMER_STATUS_LIST_CURRENT_PAGE;
  payload: number;
};
export type SetCustomerStatusListCurrentPageAC = (payload: number) => SetCustomerStatusListCurrentPageAction;

export type CustomerStatusListActions =
  | CustomerStatusListRequestAction
  | CustomerStatusListRequestSuccessAction
  | CustomerStatusListRequestFailureAction
  | SetCustomerStatusFiltersAction
  | SetCustomerStatusListSortFieldAction
  | SetCustomerStatusListPerPageAction
  | SetCustomerStatusListCurrentPageAction;
