// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_customerStatusHeader__f72et {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: var(--padding-medium);\n}\n\n.styles_customerStatusTitle__ysL\\+G {\n  margin: 6px 0;\n  font-size: 28px;\n  font-weight: 700;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/PageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,aAAa;EACb,eAAe;EACf,gBAAgB;AAClB","sourcesContent":[".customerStatusHeader {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: var(--padding-medium);\n}\n\n.customerStatusTitle {\n  margin: 6px 0;\n  font-size: 28px;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customerStatusHeader": "styles_customerStatusHeader__f72et",
	"customerStatusTitle": "styles_customerStatusTitle__ysL+G"
};
export default ___CSS_LOADER_EXPORT___;
