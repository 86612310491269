import { thresholdsConfig as thresholds } from "./thresholdsConfig";
import { CustomerStatus } from "hooks/useCustomerStatusTableConfig";

export const getSecureScoreStatus = (secureScore: number) => {
  switch (true) {
    case secureScore > thresholds.secureScoreSuccess:
      return CustomerStatus.Success;
    case secureScore < thresholds.secureScoreFailure:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};

export const getNotSignedInFor6MonthsStatus = (notSignedInFor6Months: number) => {
  switch (true) {
    case notSignedInFor6Months > thresholds.notSignedInFor6MonthsSuccess:
      return CustomerStatus.Success;
    case notSignedInFor6Months < thresholds.notSignedInFor6MonthsFailure:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};

export const getUsersWithMFAStatus = (usersWithMFA: number) => {
  switch (true) {
    case usersWithMFA === thresholds.usersWithMFAStatusSuccess:
      return CustomerStatus.Success;
    case usersWithMFA < thresholds.usersWithMFAStatusFailure:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};

export const getAdminsWithMFAStatus = (adminsWithMFA: number) => {
  switch (true) {
    case adminsWithMFA === thresholds.adminsWithMFAStatusSuccess:
      return CustomerStatus.Success;
    default:
      return CustomerStatus.Failed;
  }
};

export const getGlobalAdminsStatus = (globalAdmins: number) => {
  switch (true) {
    case globalAdmins < thresholds.globalAdminsSuccess:
      return CustomerStatus.Success;
    case globalAdmins > thresholds.globalAdminsFailure:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};

export const getBitlockerActivatedStatus = (bitlockerActivated: number) => {
  switch (true) {
    case bitlockerActivated > thresholds.bitlockerActivatedSuccess:
      return CustomerStatus.Success;
    case bitlockerActivated < thresholds.bitlockerActivatedFailure:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};

export const getWindows11Status = (windows11: number) => {
  switch (true) {
    case windows11 > thresholds.windows11Success:
      return CustomerStatus.Success;
    case windows11 < thresholds.windows11Failure:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};

export const getTPMOlderThan20Status = (tPMOlderThan20: number) => {
  switch (true) {
    case tPMOlderThan20 > thresholds["TPMOlderThan2.0Success"]:
      return CustomerStatus.Success;
    case tPMOlderThan20 < thresholds["TPMOlderThan2.0Failure"]:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};

export const getSignedInRiskUsersStatus = (signedInRiskUsers: number) => {
  switch (true) {
    case signedInRiskUsers < thresholds.signedInRiskUsersSuccess:
      return CustomerStatus.Success;
    case signedInRiskUsers > thresholds.signedInRiskUsersFailure:
      return CustomerStatus.Failed;
    default:
      return CustomerStatus.Warning;
  }
};
